import React from 'react';
import '../styles/globals.css';
import { Helmet } from 'react-helmet';
import { Footer, Grid, HeroSection, Navigation, SectionHeading } from '../components';
import { GridItem } from '../types';

const projectsSUM: GridItem[] = [
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Sofia Ticketing System',
        content: `The Sofia Ticketing System (STS) is one of the most innovative, high social impact digitalization projects in CEE successfully deployed in the past decade. In operation since 2021 and delivered for Sofia Urban Mobility Center, the transit authority of the Bulgarian capital, STS is an event-driven big-data system that delivers hybrid (open- /closed-loop) e-ticketing services across a variety of digital payment channels to enable close to 1 million journeys every day. STS has AI and ML core features embedded in the system’s design, including a ML-based passenger counting capability in the entire overground transit network. In addition to analyzing sales, validation and inspection data, the system uses 5,000 CCTV cameras in 1,200 buses, trams and trolleys to determine passenger crowding and count levels between every stop segment. Who says better journey and connection times through optimized schedules and better line efficiency are beyond reach today?​`,
      },
    ],
  },
  { type: 'image', image: 'projects-grid-1.jpg' },
  { type: 'image', image: 'projects-grid-2.jpg' },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: '',
        content: `The Open API Gateway of STS enables 3rd party merchants who wish to become travel product retailers to obtain dynamic product catalogues from the system and to receive QR issuing and Top-up services for their digital wallets, apps and other platforms. STS is designed to interact not only with merchant ecosystems, but with data companies through GTFS integrations and with data analytics solution providers, too. One of the Pay-as-you-go methods enabled on STS is the popular EMV scheme - Mobility Transit Transaction. The 5,000 validators in the overground and underground networks in Sofia accept Visa, Mastercard and Borica cards, with best fare capping enabled on our backend Fare Rule Engine.​`,
      },
    ],
  },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: '',
        content: `An online portal provides remote subscription renewal and card-top, while making available to users full journey history data and purchases receipts. STS is one of the biggest online sales systems directly integrated with the country’s National Revenue Agency, as well. Using an entirely open-source technology stack, STS system architects and ITOps teams designed an infrastructure chasis that enabled bare-metal and storage virtualization, microservice containerization, and platform messaging and system observability services that ultimately cut the TCO of the project in half compared to other projects on a similar scale. Our goals is to support the transit authority and our city to enable a robust shared mobility ecosystem and to make public transit the #1 choice of travel.​`,
      },
    ],
  },
  // { type: 'image', image: 'projects-grid-3.jpg' },
  // { type: 'image', image: 'ABM.gif' },
  { type: 'image', image: 'projects-grid-3.1.png' },
  // { type: 'image', image: 'projects-grid-4.jpg' },
  { type: 'image', image: 'projects-grid-4.1.gif' },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Mobility Analytics for the City of Sofia',
        content: `Our analytical dashboard is closely integrated with our ticketing solution for Sofia - STS. We extract diverse data with high velocity from different parts of our distributed IoT network which consists of thousands of devices, such as validators, on-board computers, cameras, inspector devices and sale stations. The data is then channelled into our big data system where all the magic happens. By using the latest microservice technologies we structure, aggregate and apply complex machine learning algorithms to help our customer (Municipality of Sofia) to monitor the entire operation of the public transport system both in real time and with historical patterns.`,
      },
      {
        title: '',
        content: `To satisfy all the needs of our customer we have developed a tailor-made data system that visualises many key aspects of Sofia’s public transit network. We have applied various data science and machine learning techniques in order to infer insights about vehicles’ occupancy levels, traffic levels, schedule deviations and origin-destination flows.`,
      },
    ],
  },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Schedule optimization',
        content: `The Covid-19 pandemic was at the same time a misfortunate event for all public transit agencies in the world but also a great opportunity for them to improve. As part of a European Union innovation program (UIA 5) we were tasked to optimize the schedule of several underperforming public transit lines in Sofia. Our task was additionally complicated by the fact that the overall budget of the public transit authority of Sofia was slashed as a function of the reduced ridership.`,
      },
      {
        title: '',
        content: `Therefore, in order to optimize we had to craft such an algorithm which can take into account both the cost constraint and the crowding component along with multiple additional factors such as headway constraints, transit network specifics, local restrictions, etc. To this end, we have developed a bleeding edge evolutionary algorithm grounded in real-time data stemming from our analytical system. This allowed us to not only propose a solution to our problem but also to evaluate it after its real-world implementation. The result was 8% cost reduction, 10 to 15% less vehicles and drivers assigned, decreasing total travelled passenger hours by more than 80 hours per line, improved distribution of drivers’ shifts and breaks, and enhanced line synchronization, all at the cost of increasing average headway by less than a minute.`,
      },
    ],
  },
  // { type: 'image', image: 'projects-grid-5.jpg' },
  { type: 'image', image: 'projects-grid-5.1.png' },
];

const projectsDUM: GridItem[] = [
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Sofia',
        content: `The project aims to enable collection and transfer of operations data in real-time to Smart Waste Management databank of Sofia Municipality as well as to reduce pick-ups frequency and operational cost. 2500 newly deployed NORD System containers are equipped with RFID tags, telematics modules are added to all NORD System trucks and monitoring and analytics dashboard is delivered.`,
      },
      {
        title: 'Veliko Tarnovo',
        content: `We evaluate fill-up rates of separate waste containers – glass and paper/plastic/metal, measure weight and volume of each of them and finally implement JIT pickup and reduce operations costs. The technical part of the project includes RFID tags and active volume sensors for 240 containers (120 glass and 120 paper/plastic/metal), active scales and UHF RFID for 2 trucks and monitoring and analytics dashboard as well.​`,
      },
      {
        title: 'Montana',
        content: `The project on the territory of Montana municipality includes 2000 containers with RFID tags, 220 of which are equipped with active sensors that measure volume, location, temperature and movement. All bins are regularly collected by four garbage collection trucks. Through the help of monitoring and analytics dashboard we not only can optimize collection routes and pick-ups schedules, but also can analyze generated waste per building and per residential area.​`,
      },
    ],
  },
  { type: 'image', image: 'projects-grid-6.jpg' },
  { type: 'image', image: 'projects-grid-7.jpg' },
  {
    type: 'card',
    button: false,
    slides: [
      {
        title: 'Albacete',
        content: `We provide analysis to Municipality of Albacete in favor of reducing pickups frequency (from 7x/week to 2x/week) and manage to reduce operations costs by min. 40%. The technical part of the project includes 100 volume active sensors for biodegradable waste containers, 20 volume active sensors for general waste containers and integration with installed dynamic and static scales on truck.`,
      },
      {
        title: 'Burgas',
        content: `The project on the territory of Burgas municipality includes RFID tags for 8300 containers for biodegradable separated waste, static and dynamic scales for 10 waste collection trucks and data collection, structuring and transfer to external systems via APIs. The main purpose is to measure weight of generated biodegradable waste in each container and hence to enable analysis of seasonable patterns of generated biodegradable waste per household.`,
      },
      {
        title: 'Vidin',
        content: `The project aims to evaluate fill-up rates and container location, implement collection route optimization for the trucks and reduce operational cost. It includes 2100 containers with RFID tags, 100 volume active sensors, 120 construction waste containers (4 m2) with volume sensors, dynamic scales, UHF RFID and telematics modules for 5 trucks as well as monitoring and analytics dashboard.`,
      },
    ],
  },
];

const ProjectsPage = () => {
  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>Theoremus</title>
        <meta name='description' content='' />
        <meta name='keywords' content='' />
        <link rel='canonical' href='' />
      </Helmet>
      <Navigation />
      <HeroSection
        backgroundImage='projects.jpg'
        copyright={false}
        heading='Projects'
        subHeading='Get to know what we have accomplished so far'
      />
      <SectionHeading
        backgroundColor='black'
        heading='Smart urban mobility'
        subHeading='Sofia Ticketing System, mobility analytics for the city of Sofia and schedule optimization'
      />
      <Grid backgroundColor='black' gridItems={projectsSUM} />
      <SectionHeading backgroundColor='white' heading='Digital waste management' subHeading='Bulgaria and Spain' />
      <Grid backgroundColor='white' gridItems={projectsDUM} />
      <Footer />
    </main>
  );
};

export default ProjectsPage;
